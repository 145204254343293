import background1 from "./background1.jpeg";
import background2 from "./background2.png";

const Menu = [
  {
    id: 1,
    imgUrl: background1,
    // "./background1.jpeg",
    head: "ShakesJournal",
    content:
      // "Shakesjournal our annual magazine is  compilation of articles and illustration that provides content to avid readers, curators or anyone who's just looking for a light hearted read.",
      "ShakesJournal our annual magazine serves as a blank canvas for ShakesJeer's authors and designers to express themselves creatively while providing a magnificent reading experience for the readers.",
  },
  {
    id: 2,
    imgUrl: background2,
    head: "Shakes-Comic",
    content: "COMING SOOOOON....",
  },
];
export default Menu;
