import First from './images/event-1.png';
import Second from './images/event-2.png';
const Menu = [
    {
        id: 1,
        month: "FEB",
        date: " XX",
        title: "COMING SOON!",
        imgUrl: First,
        venue: " XX Cafe",
        time: "At 3:00pm",
        quote: "",
        formlink: "www.google.com"
    },
    {
        id: 2,
        month: "MAR",
        date: " XX",
        title: "COMING SOON!",
        imgUrl: Second,
        venue: " XX Cafe",
        time: "At 4:00pm",
        quote: "",
        formlink: "www.google.com"
    },
    // {
    //     id: 3,
    //     month: "Jan",
    //     date: "13",
    //     title: "Shakesjeer 2",
    //     imgUrl: Second,
    //     venue: "Fountain",
    //     time: "At 4:00pm",
    //     quote: "For a woman to be complete, she has to be a blend of Paro & Chandramukhi. I feel that I am that woman. - Rekha",
    //     formlink: "www.google.com"
    // },
    // {
    //     id: 4,
    //     month: "Jan",
    //     date: "13",
    //     title: "Shakesjeer 2",
    //     imgUrl: Second,
    //     venue: "Fountain",
    //     time: "At 4:00pm",
    //     quote: "For a woman to be complete, she has to be a blend of Paro & Chandramukhi. I feel that I am that woman. - Rekha",
    //     formlink: "www.google.com"
    // }
];

export default Menu;