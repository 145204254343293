export const StreamData = [
  {
    course: "Biotechnology",
    color: "#B4AA91",
    id: 0,
    content:
      "Aims in the areas of healthcare & environment bridging Science & Engineering in Biology.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1JqfHxljrzBy4j4uFVJawLs6IRaUchRIs",
      sem3: "https://drive.google.com/folderview?id=1V5NANXVWJSj2Nz03e41ahoSzAxOLWCXP",
      sem4: "https://drive.google.com/drive/folders/1p9a6D6o6wnUQ1f6s75-WRl14T8ReoiwD?usp=sharing",
      sem5: "https://drive.google.com/drive/folders/1p9a6D6o6wnUQ1f6s75-WRl14T8ReoiwD?usp=sharing",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "Civil Engineering",
    color: "#7A4930",
    id: 1,
    content:
      "Deals with the design, construction, and maintenance of the physical and naturally built environment.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "#",
      sem3: "#",
      sem4: "#",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "Computer Engineering",
    color: "#603060",
    id: 2,
    content:
      "In-depth knowledge of computation, programming languages & integrates fields of CS.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1DVMrK8cBtkgAZyy-58cy9YIC8WA2TUYY?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1PdYJehmkGstyRIu8hjvzYnltEZ3n7BFg",
      sem3: "https://drive.google.com/drive/folders/1zaHAbQyz11vYSWWTPKpJ2jLFLDsNrKSm?usp=sharing",
      sem4: "https://drive.google.com/drive/folders/1cEPXqPaPizTG9MJXWcifWyaVpg3Tf32Z",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "CSAI",
    color: "#658539",
    id: 3,
    content:
      "Understanding of foundational topics in Computer Science, Artificial Intelligence, & ML.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1DVMrK8cBtkgAZyy-58cy9YIC8WA2TUYY?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1PdYJehmkGstyRIu8hjvzYnltEZ3n7BFg",
      sem3: "https://drive.google.com/drive/folders/1zaHAbQyz11vYSWWTPKpJ2jLFLDsNrKSm?usp=sharing",
      sem4: "https://drive.google.com/drive/folders/1cEPXqPaPizTG9MJXWcifWyaVpg3Tf32Z",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "CSDS",
    color: "#705E1E",
    id: 4,
    content:
      "Understanding of foundations, limits, and capabilities of computing.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1DVMrK8cBtkgAZyy-58cy9YIC8WA2TUYY?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1PdYJehmkGstyRIu8hjvzYnltEZ3n7BFg",
      sem3: "https://drive.google.com/drive/folders/1zaHAbQyz11vYSWWTPKpJ2jLFLDsNrKSm?usp=sharing",
      sem4: "https://drive.google.com/drive/folders/1cEPXqPaPizTG9MJXWcifWyaVpg3Tf32Z",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "ECE",
    color: "#725D64",
    id: 5,
    content:
      "Provides a perspective of the field of electronics and allows students to decide future directions.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1gtJzIePity12ay4whlYnqrEOofWydWwL",
      sem3: "https://drive.google.com/folderview?id=1Rz-MuktG095AnjNZCl53qa_C-bfKBBoH",
      sem4: "https://drive.google.com/drive/folders/1Kn3DfV_zwYu7M9__CIo60cPwhru59msu?usp=sharing",
      sem5: "https://drive.google.com/drive/folders/1Kn3DfV_zwYu7M9__CIo60cPwhru59msu?usp=sharing",
      sem6: "https://drive.google.com/drive/u/0/folders/1dD6ch08A2gdZ6uKWgaAfEt9DlBNv8aJ0",
      sem7: "https://drive.google.com/drive/folders/1trPXnEBV0s_gTao8r4MFBYQAixONyAXt?usp=sharing",
      sem8: "https://drive.google.com/drive/folders/1S9JjxIo1CIv16es4vwRXWJrnYh0j1z_C?usp=sharing",
    },
  },
  {
    course: "ECAM",
    color: "#939894",
    id: 6,
    content: "Branch includes ECE with edge knowledge of AI-ML techniques.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1gtJzIePity12ay4whlYnqrEOofWydWwL",
      sem3: "https://drive.google.com/drive/folders/1sdTVGM7rH7zS6neriYmtzDLuT9--fpRj?usp=sharing",
      sem4: "#",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "Electrical Engineering",
    color: "#009C8B",
    id: 7,
    content:
      "Practical applications of electricity in all its forms, including those of the field of electronics.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1gtJzIePity12ay4whlYnqrEOofWydWwL",
      sem3: "https://drive.google.com/drive/folders/1sdTVGM7rH7zS6neriYmtzDLuT9--fpRj?usp=sharing",
      sem4: "https://drive.google.com/drive/u/0/folders/12uUENtI6uMg0tCfUO83u6jbwTduV7EpV",
      sem5: "https://drive.google.com/drive/folders/1rZZq5xYt0ZADsP0oheas6SEVtKfA8KZk",
      sem6: "https://drive.google.com/drive/u/1/folders/1VoDBvO9CX1SqciK_EwFlsS5EwcCmDWA2",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "EIOT",
    color: "#a52a2a",
    id: 8,
    content:
      "Develops innovative services that help users to receive, control & manage information.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1gtJzIePity12ay4whlYnqrEOofWydWwL",
      sem3: "https://drive.google.com/folderview?id=1yAz4Y2SV2dzl8U8uL3DxQQlvQsrhu3Ws",
      sem4: "#",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "Geoinformatics",
    color: "#B06010",
    id: 9,
    content:
      "Combines fields of geography, geosciences & related branches of science & engineering.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1DVMrK8cBtkgAZyy-58cy9YIC8WA2TUYY?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1PdYJehmkGstyRIu8hjvzYnltEZ3n7BFg",
      sem3: "#",
      sem4: "#",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "Instrument & Control Engineering",
    color: "#2C63A0",
    id: 10,
    content:
      "Study of measurement, control & implementation of process variables in systems.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1gtJzIePity12ay4whlYnqrEOofWydWwL",
      sem3: "https://drive.google.com/drive/folders/1l8DIO2qPtse90hA0HFYNJHzG86BF_S1i?usp=sharing",
      sem4: "https://drive.google.com/drive/folders/1w2_sWHJeaveeEU6SAFJ6HlH6WhYF5FbW",
      sem5: "https://drive.google.com/drive/folders/1rZZq5xYt0ZADsP0oheas6SEVtKfA8KZk",
      sem6: "https://drive.google.com/drive/u/1/folders/1VoDBvO9CX1SqciK_EwFlsS5EwcCmDWA2",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "Information Technology",
    color: "#9A9C43",
    id: 11,
    content: "In-depth knowledge of DSA, databases, networks, IoT, ML.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1DVMrK8cBtkgAZyy-58cy9YIC8WA2TUYY?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1PdYJehmkGstyRIu8hjvzYnltEZ3n7BFg",
      sem3: "https://drive.google.com/drive/folders/1eiH67BKsrP8kXNjf9ABo3fEwsPneiQmk?usp=sharing",
      sem4: "https://drive.google.com/drive/folders/1cEPXqPaPizTG9MJXWcifWyaVpg3Tf32Z",
      sem5: "https://drive.google.com/drive/folders/1eMz9CrhqJO_OE-NQgSti7Jlg2UePCgPF?usp=sharing",
      sem6: "https://drive.google.com/drive/folders/1KGfXwIklt3KoNIBnIWP3yV4PfbUCGZgM?usp=sharing",
      sem7: "https://drive.google.com/drive/u/1/folders/1VoDBvO9CX1SqciK_EwFlsS5EwcCmDWA2",
      sem8: "https://drive.google.com/drive/folders/1p9a6D6o6wnUQ1f6s75-WRl14T8ReoiwD",
    },
  },
  {
    course: "Mathematics & Computing",
    color: "#3a485f",
    id: 12,
    content:
      "Study & analysis of abstract concepts, such as numbers & patterns.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1DVMrK8cBtkgAZyy-58cy9YIC8WA2TUYY?usp=sharing",
      sem2: "https://drive.google.com/drive/u/1/folders/1PdYJehmkGstyRIu8hjvzYnltEZ3n7BFg",
      sem3: "https://drive.google.com/drive/folders/1ezH5HZGpR_34FCCTdI1RpRkyXcHxROqu?usp=sharing",
      sem4: "https://drive.google.com/drive/folders/1K6jV-ItFP2q20VIwdx8P018k5bTLlO2v?usp=sharing",
      sem5: "https://drive.google.com/folderview?id=1MvaQjJR9FUzyUfC-spbNeskAny7d-Df-",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "Mechanical Engineering",
    color: "#4e3e5b",
    id: 13,
    content:
      "Combines engineering physics & mathematics principles with materials science",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/folders/1YaupObDonb_xx4FPENN3WP4usMt6ijEA?usp=sharing",
      sem3: "https://drive.google.com/folderview?id=1I7c7APn3g_VMPizem5fDy9OWMf_GKyCA",
      sem4: "#",
      sem5: "#",
      sem6: "https://drive.google.com/folderview?id=10eCH9KtV5Ly0pI6ZQPL81HNhRdtwVgQa",
      sem7: "#",
      sem8: "#",
    },
  },
  {
    course: "MPAE",
    color: "#0b575b",
    id: 14,
    content:
      "Deals with the concepts & processes involved in the design & development of machines.",
    links: {
      sem1: "https://drive.google.com/drive/folders/1lg4Fez7CP3aFv-F64d5M6sZOoFp7g_dl?usp=sharing",
      sem2: "https://drive.google.com/drive/folders/1YaupObDonb_xx4FPENN3WP4usMt6ijEA?usp=sharing",
      sem3: "https://drive.google.com/folderview?id=1-w7N9qj3VPDRGIWSSqPypKMMnVUtCCgr",
      sem4: "#",
      sem5: "#",
      sem6: "#",
      sem7: "#",
      sem8: "#",
    },
  },
];
